const myTheme = {
  // Alert
  alert: {
    base: "p-4 pl-12 relative rounded-lg leading-5",
    withClose: "pr-12",
    success: "bg-green-50 text-green-900 dark:bg-green-600 dark:text-gray-300",
    danger: "bg-red-50 text-red-900 dark:bg-red-600 dark:text--gray-300",
    warning: "bg-yellow-50 text-yellow-900 dark:bg-yellow-600 dark:text-white",
    neutral: "bg-gray-50 text-gray-800 dark:bg-gray-700 dark:text-gray-300",
    info: "bg-blue-50 text-blue-900 dark:bg-blue-600 dark:text-white",
    icon: {
      base: "h-5 w-5",
      success: "text-green-400 dark:text-green-300",
      danger: "text-red-400 dark:text-red-300",
      warning: "text-yellow-400 dark:text-yellow-100",
      neutral: "text-gray-300 dark:text-gray-500",
      info: "text-blue-400 dark:text-blue-300",
    },
  },
  // Pagination
  pagination: {
    base: "flex flex-col justify-between text-xs sm:flex-row text-gray-600 dark:text-white",
  },
  // TableFooter
  tableFooter: {
    base: "px-3 py-3 border-t-2 border-gray-200 dark:border-cieslaOrange bg-white text-gray-500 dark:text-white dark:bg-cieslaGranat",
  },
  // TableRow
  tableRow: {
    base: "text-xs hover:bg-gray-100 dark:hover:bg-cieslaGranatLighter",
  },
  // TableHeader
  tableHeader: {
    base: "text-xs font-semibold font-serif tracking-wide text-gray-500 border border-gray-200 dark:border-cieslaGranat bg-gray-100 dark:text-white dark:bg-cieslaBlack",
  },
  table: {
    base: "table-collapse",
  },
  // TableContainer
  tableContainer: {
    base: "w-full overflow-hidden border border-gray-200 dark:border-cieslaGranat rounded-2xl ring-1 ring-black ring-opacity-5",
  },
  // TableCell
  tableCell: {
    base: "px-2 py-3 font-serif whitespace-normal",
  },
  // TableBody
  tableBody: {
    base: "bg-white divide-y divide-gray-100 divide-dashed dark:divide-cieslaBlack dark:bg-cieslaGranat text-gray-700 dark:text-white",
  },
  // DropdownItem
  // this is the <li> that lives inside the Dropdown <ul>
  // you're probably looking for the dropdownItem style inside button
  dropdownItem: {
    base: "mb-2 last:mb-0",
  },
  // Dropdown
  dropdown: {
    base: "absolute w-56 p-2 mt-2 text-gray-600 bg-white dark:bg-gray-700 dark:border-gray-700 border border-gray-100 rounded-lg shadow-lg min-w-max-content",
    align: {
      left: "left-0",
      right: "right-0",
    },
  },
  // Avatar
  avatar: {
    base: "relative rounded-full inline-block",
    size: {
      large: "w-10 h-10",
      regular: "w-8 h-8",
      small: "w-6 h-6",
    },
  },
  // Modal
  modal: {
    base: "w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-cieslaGranatLighter sm:rounded-lg sm:m-4 sm:max-w-xl custom-modal",
  },
  // ModalBody
  modalBody: {
    base: "mb-6 text-sm text-gray-700 dark:text-white",
  },
  // ModalFooter
  modalFooter: {
    base: "flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-3 sm:space-y-0 sm:space-x-4 sm:flex-row bg-gray-100 dark:bg-cieslaGranat",
  },
  // ModalHeader
  modalHeader: {
    base: "mt-4 mb-2 text-lg font-semibold text-gray-700 dark:text-white ",
  },
  // Badge
  badge: {
    base: "inline-flex px-2 text-xs font-medium leading-5 rounded-full",
    success:
      "text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-100",
    danger: "text-red-500 bg-red-100 dark:text-red-100 dark:bg-red-800",
    warning: "text-yellow-500 bg-yellow-100 dark:text-white dark:bg-yellow-600",
    neutral: "text-gray-500 bg-gray-100 dark:text-gray-100 dark:bg-gray-700",
    primary: "text-blue-500 bg-blue-100 dark:text-white dark:bg-blue-800",
  },
  // Backdrop
  backdrop: {
    base: "fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center",
  },
  // Textarea
  textarea: {
    base: "block w-full text-sm dark:text-gray-100 rounded-md focus:outline-none form-textarea",
    active:
      "focus:border-purple-400 border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:ring-gray-300 focus:ring focus:ring-purple-300",
    disabled: "cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-700",
    valid:
      "border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200",
    invalid:
      "border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:ring focus:ring-red-200 dark:focus:ring-red-200",
  },
  // Select
  select: {
    base: "block w-full px-2 py-1 text-sm dark:text-white focus:outline-none rounded-md form-select dark:focus:bg-cieslaGranatLight",
    active:
      "focus:border-gray-200 border-gray-200 dark:border-cieslaGranatLightest focus:shadow-none dark:bg-cieslaGranatLighter dark:border-gray-600",
    select: "leading-5",
    disabled: "cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-700",
    valid:
      "border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 ",
    invalid:
      "border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 ",
  },
  // Label
  label: {
    base: "block text-sm text-gray-700 dark:text-white",
    // check and radio get this same style
    check: "inline-flex items-center",
    disabled: "opacity-50 cursor-not-allowed",
  },
  // Input
  input: {
    //base: "block w-full px-3 py-1 text-sm focus:outline-none dark:text-white leading-5 rounded-md dark:placeholder-gray-400",
    base: "block w-full px-3 py-1 text-sm focus:outline-none dark:text-white leading-5 rounded-md dark:placeholder-gray-400",
    active:
      "focus:border-gray-200 border-gray-200 dark:border-cieslaGranatLightest dark:hover:border-cieslaOrange dark:focus:border-cieslaOrange dark:focus:ring-gray-300 dark:bg-cieslaGranatLighter",
    disabled: "cursor-not-allowed opacity-50 bg-gray-300 dark:bg-cieslaGranat",
    valid:
      "border-green-600 dark:bg-cieslaGranat focus:border-green-400 dark:focus:border-green-400 ",
    invalid:
      "border-red-600 dark:bg-cieslaGranat focus:border-red-400 dark:focus:border-red-400",
    radio:
      "text-green-500 form-radio focus:border-green-400 focus:outline-none",
    checkbox:
      "text-green-500 form-checkbox focus:border-green-500 focus:outline-none",
  },
  // HelperText
  helperText: {
    base: "text-xs",
    valid: "text-green-600 dark:text-green-400",
    invalid: "text-red-600 dark:text-red-400",
  },
  // Card
  card: {
    base: "min-w-0 rounded-lg ring-0 ring-black ring-opacity-4 overflow-hidden ",
    default: "bg-white dark:bg-cieslaGranat",
  },
  cardBody: {
    base: "p-3",
  },
  span: {
    base: "dark:text-white",
  },
  // Button
  button: {
    base: "align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-104 font-semibold focus:outline-none dark:hover:border-cieslaGranatLightest dark:focus:border-cieslaGranatLightest",
    block: "w-full",
    size: {
      larger: "px-10 py-4 rounded-3xl",
      large: "px-5 py-3 rounded-3xl",
      regular: "px-4 py-2 rounded-3xl text-sm",
      small: "px-3 py-1 rounded-3xl text-sm",
      icon: {
        larger: "p-4 rounded-3xl",
        large: "p-3 rounded-3xl",
        regular: "p-2 rounded-3xl",
        small: "p-2 rounded-3xl",
      },
      pagination: "px-3 py-1 rounded-md text-xs",
    },
    // styles applied to the SVG icon
    icon: {
      larger: "h-5 w-5",
      large: "h-5 w-5",
      regular: "h-5 w-5",
      small: "h-3 w-3",
      left: "mr-2 -ml-1",
      right: "ml-2 -mr-1",
    },
    primary: {
      base: "text-cieslaBlack dark:text-cieslaBlack hover:text-white dark:hover:text-gray-300 bg-cieslaGreen dark:bg-cieslaGreen border-0",
      active:
        "active:bg-orange-400 hover:bg-cieslaOrange dark:hover:bg-cieslaOrange dark:active:bg-cieslaBlack",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "text-cieslaBlack dark:text-white focus:outline-none border-0",
      active:
        "rounded-lg px-4 w-full mr-3 flex items-center justify-center cursor-pointer h-12",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline2: {
      base: "text-gray-600 dark:text-white focus:outline-none border-0",
      active:
        "rounded-lg w-full flex items-center justify-center cursor-pointer h-12",
      disabled: "opacity-50 cursor-not-allowed",
    },
    img: {
      base: "border-none text-white",
      active:
        "rounded-lg border px-4 w-full mr-3 flex items-center justify-center cursor-pointer h-12 bg-gray-200",
      disabled: "opacity-50 cursor-not-allowed bg-gray-300",
    },
    link: {
      base: "text-gray-600 dark:text-white focus:outline-none border-transparent ",
      active:
        "active:bg-transparent dark:hover:text-cieslaOrange hover:text-cieslaOrange hover:bg-opacity-10",
      disabled: "opacity-50 cursor-not-allowed",
    },
    // this is the button that lives inside the DropdownItem
    /*dropdownItem: {
      base: "inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200",
    },*/
  },
};
export default myTheme;
